// Library imports
import React, { useState } from 'react';
import { Container, Row, Col, Nav, ListGroup, Card } from 'react-bootstrap';
// Component imports
import TwoColumnView from '../../../components/card_components/TwoColumnView';
import NoData from '../../../components/page_components/NoData';
// Util imports
import { addPlusSign, formatOdd, shortName } from '../../../utils';
// Styled components
import { HighLightedField, InnerContent, CardTitle } from './styles';
import { LabelValueListGroup } from '../../App/styles';

const LineData = ({ game, sport, demo }) => {
    const [type, setType] = useState(sport === 'mlb' || sport === 'nhl' ? 'moneyline' : 'spread');
    const [bookType, setBookType] = useState(sport === 'mlb' || sport === 'nhl' ? 'moneylines' : 'spreads');

    const calcAverageMoneyline = team => {
        if (!team.odds) {
            return 0;
        }
        if (team.odds.fullGame.moneyline > 0) {
            return formatOdd(team.averageMoneylines?.dog.avgML.toFixed(0), 'moneyline');
        } else {
            return formatOdd(team.averageMoneylines?.favorite.avgML.toFixed(0), 'moneyline');
        }
    };

    const calcOddMovementVariant = team => {
        if (type === 'moneyline') {
            return team.odds?.fullGame?.moneyline > team.openMoneyLine ? 'success' : team.odds?.fullGame?.moneyline < team.openMoneyLine ? 'danger' : 'light';
        } else {
            const openingSpread = team.odds?.fullGame?.spread < 0 ? game.openingSpread * -1 : game.openingSpread;
            return team.odds?.fullGame?.spread > openingSpread ? 'success' : team.odds?.fullGame?.spread < openingSpread ? 'danger' : 'light';
        }
    };

    const createLineDataJSX = team => {
        return (
            <Card>
                <CardTitle color={team.color}>{shortName(team)}</CardTitle>
                <LabelValueListGroup direction="row" variant="flush">
                    <ListGroup.Item>
                        <label>Open</label>
                        <HighLightedField variant="light">
                            {type === 'moneyline'
                                ? formatOdd(team.openMoneyLine, 'moneyline')
                                : formatOdd(team.odds?.fullGame?.spread < 0 ? game.openingSpread * -1 : game.openingSpread)}
                        </HighLightedField>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <label>Now</label>
                        <HighLightedField variant={calcOddMovementVariant(team)}>
                            {type === 'moneyline' ? formatOdd(team.odds?.fullGame?.moneyline, 'moneyline') : formatOdd(team.odds?.fullGame?.spread)}
                        </HighLightedField>
                    </ListGroup.Item>
                    {type === 'moneyline' ? (
                        <ListGroup.Item>
                            <label>Avg.</label>
                            <HighLightedField variant="light">{calcAverageMoneyline(team)}</HighLightedField>
                        </ListGroup.Item>
                    ) : (
                        <></>
                    )}
                </LabelValueListGroup>
            </Card>
        );
    };

    const bookDataJSX = team => {
        const lineType = team.teamId === game.awayTeam.teamId ? 'awayLine' : 'homeLine';
        const bookData = game.bookData[bookType].filter(book => !isNaN(book[lineType])).map(book => book[lineType]);
        const areAllValuesSame = bookData.every(value => value === bookData[0]);
        return (
            <Card>
                <CardTitle color={team.color}>{shortName(team)}</CardTitle>
                <LabelValueListGroup direction="row" variant="flush">
                    {game.bookData[bookType] ? (
                        game.bookData[bookType].map((book, i) => {
                            return (
                                <ListGroup.Item
                                    key={`${book.label} ${i}`}
                                    style={{
                                        backgroundColor: areAllValuesSame
                                            ? 'transparent'
                                            : book[lineType] === Math.min(...bookData)
                                            ? '#f8d7da'
                                            : book[lineType] === Math.max(...bookData)
                                            ? '#d4edda'
                                            : 'transparent',
                                    }}
                                >
                                    <label
                                        style={{
                                            color: areAllValuesSame
                                                ? book.color
                                                : book[lineType] === Math.min(...bookData)
                                                ? '#721c24'
                                                : book[lineType] === Math.max(...bookData)
                                                ? '#155724'
                                                : book.color,
                                        }}
                                    >
                                        {book.label}
                                    </label>
                                    <span
                                        style={{
                                            color: areAllValuesSame
                                                ? book.color
                                                : book[lineType] === Math.min(...bookData)
                                                ? '#721c24'
                                                : book[lineType] === Math.max(...bookData)
                                                ? '#155724'
                                                : book.color,
                                        }}
                                    >
                                        {addPlusSign(book[lineType])}
                                    </span>
                                </ListGroup.Item>
                            );
                        })
                    ) : (
                        <></>
                    )}
                </LabelValueListGroup>
            </Card>
        );
    };

    return (
        <InnerContent>
            <Container className="mt-2" fluid>
                <Row>
                    <Col>
                        <Nav variant="tabs" className="mb-2" activeKey={type}>
                            <Nav.Item>
                                <Nav.Link onClick={() => setType('moneyline')} eventKey="moneyline">
                                    Moneyline
                                </Nav.Link>
                            </Nav.Item>
                            {sport === 'mlb' || sport === 'nhl' ? (
                                <></>
                            ) : (
                                <Nav.Item>
                                    <Nav.Link onClick={() => setType('spread')} eventKey="spread">
                                        Spread
                                    </Nav.Link>
                                </Nav.Item>
                            )}
                            <Nav.Item>
                                <Nav.Link disabled={demo} onClick={() => setType('books')} eventKey="books">
                                    Books
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
                {(type === 'books' && !game.bookData?.[bookType]) || (type === 'moneyline' && !game.awayTeam.odds?.fullGame?.moneyline) ? (
                    <NoData title="No data" message="There is currently no line data for this game.  Check back soon." />
                ) : type === 'moneyline' || type === 'spread' ? (
                    <TwoColumnView columnOneJSX={createLineDataJSX(game.awayTeam)} columnTwoJSX={createLineDataJSX(game.homeTeam)} />
                ) : (
                    <div>
                        <Nav className="d-flex mb-2" activeKey={bookType} variant="pills">
                            <Nav.Item>
                                <Nav.Link onClick={() => setBookType('moneylines')} eventKey="moneylines">
                                    Moneylines
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={() => setBookType('spreads')} eventKey="spreads">
                                    Spreads
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <TwoColumnView columnOneJSX={bookDataJSX(game.awayTeam)} columnTwoJSX={bookDataJSX(game.homeTeam)} />
                    </div>
                )}
            </Container>
        </InnerContent>
    );
};

export default LineData;
