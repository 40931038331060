// Library imports
import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { isUserAdmin } from '../../controllers/UserController';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faCog, faDatabase, faList, faPlus, faPowerOff, faServer, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
// Component imports
import Picks from './profile_components/Picks';
import Settings from './profile_components/Settings';
import VIPs from './profile_components/VIP';
import API from './profile_components/API';
import Investor from './profile_components/Investor';
import SavedData from './profile_components/SavedData';
import CreatePick from './profile_components/CreatePick';
import { Header } from '../../components/header';
import Sidebar from '../Sidebar';
import { LoadingSpinner } from '../../components/page_components/LoadingSpinner';
import Footer from '../../components/footer';
import UpgradeTooltip from '../../components/page_components/UpgradeTooltip';
// Util imports
import { capitalize } from '../../utils';
// Firebase imports
import firebase from 'firebase';
// Action imports
import { setDrawer } from '../../store/slices/appSlice';
import { fetchUser } from '../../store/slices/userSlice';
// Styled components
import { ProfileEmail, ProfileHeader, ProfileUserName, ProfileImage } from './styles';

const Profile = () => {
    const history = useHistory();
    // Redux
    const dispatch = useDispatch();
    const { user, userLoading } = useSelector(state => state.user);
    // Local state
    let params = queryString.parse(window.location.href.split('?')[1]);
    const [subpage, setSubpage] = useState(params.subpage ? params.subpage : 'picks');
    const [admin, setAdmin] = useState(false);

    useEffect(() => {
        dispatch(setDrawer(''));
        dispatch(fetchUser());
    }, []);

    useEffect(() => {
        async function assignAdmin() {
            try {
                let isAdmin = await isUserAdmin();
                setAdmin(isAdmin);
            } catch (error) {
                console.log(`Error getting admin data - Profile page. ${error}`);
            }
        }
        if (user.email) {
            assignAdmin();
        }
    }, [user]);

    if (userLoading) {
        return (
            <div className="page-wrapper" style={{ height: '100vh' }}>
                <LoadingSpinner loading={userLoading} />
            </div>
        );
    }

    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Profile</title>
                <meta name="description" content="User profile page.  Track your pick data across different sports and bet types." />
            </Helmet>
            <Header currentPage="profile" lightMode={true} />
            <div style={{ background: '#fff' }}>
                <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                    <Navbar.Brand className="order-lg-0 mr-auto order-1 d-sm-block d-lg-none">
                        {capitalize(subpage === 'investor' ? 'Portoflio' : subpage)}
                    </Navbar.Brand>
                    <Navbar.Toggle className="mr-2" aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse className="justify-content-center">
                        <Nav
                            activeKey={subpage}
                            onSelect={selectedKey => {
                                if (selectedKey === 'logout') {
                                    firebase.auth().signOut();
                                } else {
                                    setSubpage(selectedKey);
                                    history.push(`?subpage=${selectedKey}`);
                                }
                            }}
                        >
                            <Nav.Link className="d-flex align-items-center mr-2" eventKey="picks">
                                <FontAwesomeIcon className="mr-1" icon={faList} /> Picks
                            </Nav.Link>
                            {user.reciept.type === 'free-account' ? (
                                <UpgradeTooltip
                                    children={
                                        <Nav.Link className="d-flex align-items-center mr-2" eventKey="" disabled={true}>
                                            <FontAwesomeIcon className="mr-1" icon={faChartLine} />
                                            Portfolio
                                        </Nav.Link>
                                    }
                                />
                            ) : (
                                <Nav.Link className="d-flex align-items-center mr-2" eventKey="investor">
                                    <FontAwesomeIcon className="mr-1" icon={faChartLine} />
                                    Portfolio
                                </Nav.Link>
                            )}
                            <Nav.Link className="d-flex align-items-center mr-2" eventKey="settings">
                                <FontAwesomeIcon className="mr-1" icon={faCog} /> Settings
                            </Nav.Link>
                            {admin || user.uid === '4LQHIrWNMWfUSS7vINFYvFR0Axl1' ? (
                                <Nav.Link className="d-flex align-items-center mr-2" eventKey="create-pick">
                                    <FontAwesomeIcon className="mr-1" icon={faPlus} /> Create Pick
                                </Nav.Link>
                            ) : (
                                <></>
                            )}
                            {admin ? (
                                <>
                                    <Nav.Link className="d-flex align-items-center mr-2" eventKey="vips">
                                        <FontAwesomeIcon className="mr-1" icon={faUserCheck} /> VIPs
                                    </Nav.Link>
                                    <Nav.Link className="d-flex align-items-center mr-2" eventKey="api">
                                        <FontAwesomeIcon className="mr-1" icon={faServer} /> API
                                    </Nav.Link>
                                    <Nav.Link className="d-flex align-items-center mr-2" eventKey="data">
                                        <FontAwesomeIcon className="mr-1" icon={faDatabase} /> Data
                                    </Nav.Link>
                                </>
                            ) : (
                                <></>
                            )}
                            <Nav.Link className="d-flex align-items-center mr-2" eventKey="logout">
                                <FontAwesomeIcon className="mr-1" icon={faPowerOff} /> Logout
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
                <Container fluid className="px-md-5 py-3">
                    <ProfileHeader>
                        <ProfileImage src={user.avatarSrc} />
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: '5px',
                            }}
                        >
                            <ProfileUserName>{user.displayName}</ProfileUserName>
                            <ProfileEmail>{user.email}</ProfileEmail>
                        </div>
                    </ProfileHeader>
                    {subpage === 'picks' ? <Picks key={'picks'} /> : <></>}
                    {subpage === 'investor' ? <Investor key={'investor'} /> : <></>}
                    {subpage === 'settings' ? <Settings key={'settings'} /> : <></>}
                    {subpage === 'vips' ? <VIPs key={'vips'} /> : <></>}
                    {subpage === 'api' ? <API key={'api-admin'} /> : <></>}
                    {subpage === 'data' ? <SavedData key={'data-admin'} /> : <></>}
                    {subpage === 'create-pick' ? <CreatePick key={'create-pick'} /> : <></>}
                </Container>
            </div>
            <Sidebar tabs={['vip']} />
            <Footer />
        </div>
    );
};

export default Profile;
