// Library imports
import React from 'react';
import { Row, Col, Container, Card, ListGroup, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
// Component imports
import TwoColumnView from '../../../components/card_components/TwoColumnView';
import NoData from '../../../components/page_components/NoData';
// Util imports
import { shortName } from '../../../utils';
import { ADVANCED_SEARCH_SCREEN_SIZE } from '../../../utils/constants';
import useScreenWidth from '../../../utils/hooks/useScreenWidth';
// Styled components
import { CardTitle, InnerContent } from './styles';
import { LabelValueListGroup } from '../../App/styles';

const Situations = ({ game, setSubpage, demo }) => {
    const history = useHistory();
    const windowWidth = useScreenWidth();

    if (!game.situations) {
        return <NoData title="No data" message="There are currently no situations for this game.  Check back soon." />;
    }
    const otherSituations = game.situations.filter(situation => situation.value && situation.type === 'other');
    return (
        <InnerContent>
            <Container className="mt-2" fluid>
                <TwoColumnView
                    columnOneJSX={
                        <Card>
                            <CardTitle color={game.awayTeam.color}>{shortName(game.awayTeam)}</CardTitle>
                            <LabelValueListGroup variant="flush">
                                {game.awayTeam.recordData ? (
                                    <ListGroup.Item style={{ borderTop: '0px' }}>
                                        <label>Away Record</label>
                                        <span>
                                            {shortName(game.awayTeam)} are <b>{game.awayTeam.recordData.awayRecord}</b> on the road this year.
                                        </span>
                                    </ListGroup.Item>
                                ) : (
                                    <></>
                                )}
                                {game.situations
                                    .filter(situation => situation.type === 'team' && situation.teamId === game.awayTeam.teamId)
                                    .map(situation => {
                                        return (
                                            <ListGroup.Item key={situation.id} style={{ borderTop: '0px' }}>
                                                <label>{situation.label}</label>
                                                <span>{situation.description}</span>
                                                {situation.queryString ? (
                                                    <Button
                                                        variant="link"
                                                        size="sm"
                                                        disabled={demo || windowWidth < ADVANCED_SEARCH_SCREEN_SIZE}
                                                        onClick={() => {
                                                            history.push({
                                                                pathname: location.pathname,
                                                                search: situation.queryString,
                                                            });
                                                            setSubpage('trends');
                                                        }}
                                                        style={{ padding: 0, marginTop: '5px', display: 'flex', gap: '5px', alignItems: 'center' }}
                                                    >
                                                        Query Trend
                                                        <FontAwesomeIcon icon={faAngleRight} />
                                                    </Button>
                                                ) : (
                                                    <></>
                                                )}
                                            </ListGroup.Item>
                                        );
                                    })}
                            </LabelValueListGroup>
                        </Card>
                    }
                    columnTwoJSX={
                        <Card>
                            <CardTitle color={game.homeTeam.color}>{shortName(game.homeTeam)}</CardTitle>
                            <LabelValueListGroup variant="flush">
                                {game.homeTeam.recordData ? (
                                    <ListGroup.Item style={{ borderTop: '0px' }}>
                                        <label>Home Record</label>
                                        <span>
                                            {shortName(game.homeTeam)} are <b>{game.homeTeam.recordData.homeRecord}</b> at home this year.
                                        </span>
                                    </ListGroup.Item>
                                ) : (
                                    <></>
                                )}
                                {game.situations
                                    .filter(situation => situation.type === 'team' && situation.teamId === game.homeTeam.teamId)
                                    .map(situation => {
                                        return (
                                            <ListGroup.Item key={situation.id} style={{ borderTop: '0px' }}>
                                                <label>{situation.label}</label>
                                                <span>{situation.description}</span>
                                                {situation.queryString ? (
                                                    <Button
                                                        variant="link"
                                                        size="sm"
                                                        disabled={demo || windowWidth < ADVANCED_SEARCH_SCREEN_SIZE}
                                                        onClick={() => {
                                                            history.push({
                                                                pathname: location.pathname,
                                                                search: situation.queryString,
                                                            });
                                                            setSubpage('trends');
                                                        }}
                                                        style={{ padding: 0, marginTop: '5px', display: 'flex', gap: '5px', alignItems: 'center' }}
                                                    >
                                                        Query Trend
                                                        <FontAwesomeIcon icon={faAngleRight} />
                                                    </Button>
                                                ) : (
                                                    <></>
                                                )}
                                            </ListGroup.Item>
                                        );
                                    })}
                            </LabelValueListGroup>
                        </Card>
                    }
                />
                <Row className="mt-1">
                    <Col>
                        <Card>
                            <CardTitle>Other Situations</CardTitle>
                            <LabelValueListGroup variant="flush">
                                {!otherSituations.length ? (
                                    <NoData title="No data" message="There is currently other situation data for this matchup." />
                                ) : (
                                    otherSituations.map(situation => {
                                        return (
                                            <ListGroup.Item key={situation.id} style={{ borderTop: '0px' }}>
                                                <label>{situation.label}</label>
                                                <span>{situation.description}</span>
                                            </ListGroup.Item>
                                        );
                                    })
                                )}
                            </LabelValueListGroup>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </InnerContent>
    );
};

export default Situations;
