// Library imports
import React, { useState } from 'react';
import { ResponsivePie } from '@nivo/pie';
import { Card, Col, Row, Container, Alert, Nav } from 'react-bootstrap';
// Component imports
import TwoColumnView from '../../../components/card_components/TwoColumnView';
// Styled imports
import { CardTitle, InnerContent } from './styles';
import NoData from '../../../components/page_components/NoData';

const PublicMoneyPercent = ({ sport, game }) => {
    const [type, setType] = useState(sport === 'mlb' || sport === 'nhl' ? 'moneyline' : 'spread');

    return (
        <InnerContent>
            <Container className="mt-2" fluid>
                <Row>
                    <Col>
                        <Nav variant="tabs" className="mb-2" activeKey={type}>
                            <Nav.Item>
                                <Nav.Link onClick={e => setType('moneyline')} eventKey="moneyline">
                                    Moneyline
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={e => setType('spread')} eventKey="spread">
                                    Spread
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
                <TwoColumnView
                    columnOneJSX={
                        <Card>
                            <CardTitle>Ticket %</CardTitle>
                            <Card.Body style={{ height: '165px', padding: 0 }}>
                                {(type === 'moneyline' && !game.awayTeam?.mlPercent && !game.homeTeam?.mlPercent) ||
                                (type === 'spread' && !game.awayTeam?.spreadPercent && !game.homeTeam?.spreadPercent) ? (
                                    <NoData
                                        title="No data"
                                        message="There is currently no % data."
                                        headerFontSize={14}
                                        messageFontSize={12}
                                        variant="light"
                                        containerStyle={{ textAlign: 'center' }}
                                    />
                                ) : (
                                    <ResponsivePie
                                        data={[
                                            {
                                                id: game.homeTeam.abbr,
                                                label: game.homeTeam.abbr,
                                                value: type === 'moneyline' ? game.homeTeam.mlPercent : game.homeTeam.spreadPercent,
                                            },
                                            {
                                                id: game.awayTeam.abbr,
                                                label: game.awayTeam.abbr,
                                                value: type === 'moneyline' ? game.awayTeam.mlPercent : game.awayTeam.spreadPercent,
                                            },
                                        ]}
                                        colors={[game.homeTeam.color, game.awayTeam.color]}
                                        margin={{
                                            top: 5,
                                            right: 2,
                                            bottom: 5,
                                            left: 2,
                                        }}
                                        padAngle={3}
                                        innerRadius={0.2}
                                        borderWidth={2}
                                        enableArcLinkLabels={false}
                                        arcLabel={e => e.id + ' ' + e.value + '%'}
                                        arcLabelsSkipAngle={10}
                                        arcLabelsTextColor="#ffffff"
                                    />
                                )}
                            </Card.Body>
                        </Card>
                    }
                    columnTwoJSX={
                        <Card>
                            <CardTitle>Money %</CardTitle>
                            <Card.Body style={{ height: '165px', padding: 0 }}>
                                {(type === 'moneyline' && !game.awayTeam?.mlMoneyPercent && !game.homeTeam?.mlMoneyPercent) ||
                                (type === 'spread' && !game.awayTeam?.spreadMoneyPercent && !game.homeTeam?.spreadMoneyPercent) ? (
                                    <NoData
                                        title="No data"
                                        message="There is currently no % data."
                                        headerFontSize={14}
                                        messageFontSize={12}
                                        variant="light"
                                        containerStyle={{ textAlign: 'center' }}
                                    />
                                ) : (
                                    <ResponsivePie
                                        data={[
                                            {
                                                id: game.homeTeam.abbr,
                                                label: game.homeTeam.abbr,
                                                value: type === 'moneyline' ? game.homeTeam.mlMoneyPercent : game.homeTeam.spreadMoneyPercent,
                                            },
                                            {
                                                id: game.awayTeam.abbr,
                                                label: game.awayTeam.abbr,
                                                value: type === 'moneyline' ? game.awayTeam.mlMoneyPercent : game.awayTeam.spreadMoneyPercent,
                                            },
                                        ]}
                                        colors={[game.homeTeam.color, game.awayTeam.color]}
                                        margin={{
                                            top: 5,
                                            right: 2,
                                            bottom: 5,
                                            left: 2,
                                        }}
                                        padAngle={3}
                                        innerRadius={0.2}
                                        borderWidth={2}
                                        enableArcLinkLabels={false}
                                        arcLabel={e => e.id + ' ' + e.value + '%'}
                                        arcLabelsSkipAngle={10}
                                        arcLinkLabelsDiagonalLength={1}
                                        arcLabelsTextColor="#ffffff"
                                    />
                                )}
                            </Card.Body>
                        </Card>
                    }
                />
            </Container>
        </InnerContent>
    );
};

export default PublicMoneyPercent;
