// Library imports
import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
// Component imports
import NoData from '../../../../components/page_components/NoData';
// Action imports
import { setData } from '../../../../store/slices/queryBuilderSlice';
// Util imports
import useScreenWidth from '../../../../utils/hooks/useScreenWidth';
import { ADVANCED_SEARCH_SCREEN_SIZE } from '../../../../utils/constants';
// Styled components
import { InnerContent } from '../styles';
import { QueryStringContainer, RunQueryContainer, TeamName, TeamNameContainer } from './styles';

const AdvancedSearch = ({ game, setSubpage, demo }) => {
    // Redux
    const dispatch = useDispatch();
    // Local state
    const windowWidth = useScreenWidth();

    const query = {
        awayTeam: game.awayTeam.teamId,
        awayOddsSymbol: '<',
        awayOdds: game.awayTeam.odds?.fullGame?.moneyline,
        homeTeam: game.homeTeam.teamId,
        homeOddsSymbol: '>',
        homeOdds: game.homeTeam.odds?.fullGame?.moneyline,
    };

    const runQuery = queryData => {
        dispatch(setData(queryData));
        setSubpage('search-builder');
    };

    if (windowWidth < ADVANCED_SEARCH_SCREEN_SIZE) {
        return <NoData title={'Please try again on a desktop.'} message={'You must be on a desktop device to use this feature.'} variant="warning" />;
    }

    return (
        <InnerContent
            style={{
                backgroundColor: '#f1f1f1',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <RunQueryContainer>
                <QueryStringContainer>
                    <TeamNameContainer>
                        <TeamName color={game.awayTeam.color}>{game.awayTeam.fullName}</TeamName>
                        <span className="subtext">Away team</span>
                    </TeamNameContainer>
                    <span style={{ marginRight: '10px', marginLeft: '10px' }}>vs.</span>
                    <TeamNameContainer>
                        <TeamName color={game.homeTeam.color}>{game.homeTeam.fullName}</TeamName>
                        <span className="subtext" style={{ marginLeft: 'auto' }}>
                            Home team
                        </span>
                    </TeamNameContainer>
                </QueryStringContainer>
                <Button size="sm" variant="success" onClick={e => runQuery({ awayTeam: query.awayTeam, homeTeam: query.homeTeam })} disabled={demo}>
                    Run Query
                </Button>
            </RunQueryContainer>
        </InnerContent>
    );
};

export default AdvancedSearch;
