// Library imports
import React, { useState } from 'react';
import { Container, Card, ListGroup, Row, Col, Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import moment from 'moment';
// Component imports
import Table from '../../../components/page_components/TableV2';
import TwoColumnView from '../../../components/card_components/TwoColumnView';
// Util imports
import { shortName } from '../../../utils';
// Styled components
import { CardTitle, InnerContent } from './styles';
import { LabelValueListGroup } from '../../App/styles';

const Schedule = ({ game, history }) => {
    // Redux
    const { teams } = useSelector(state => state.app);
    const awayData = teams.find(team => team.teamId === game.awayTeam.teamId);
    const homeData = teams.find(team => team.teamId === game.homeTeam.teamId);
    var commonOppIds = [];
    if (awayData?.schedule && homeData?.schedule) {
        const awaySet = new Set(awayData?.schedule.filter(game => game.points && game.opp_pts).map(item => item.oppId));
        const homeSet = new Set(homeData?.schedule.filter(game => game.points && game.opp_pts).map(item => item.oppId));
        commonOppIds = [...awaySet].filter(id => homeSet.has(id));
    }
    // Local state
    const [scheduleTab, setScheduleTab] = useState('Last 5 games');

    const renderLastFiveGames = team => {
        const schedule = team.teamId === game.awayTeam.teamId ? awayData.schedule : homeData.schedule;
        return (
            <Card>
                <CardTitle color={team.color} style={{ padding: '15px 10px 0px 10px' }}>
                    {shortName(team)}
                </CardTitle>
                <Card.Body style={{ paddingTop: 0, padding: '5px 10px' }}>
                    {!history ? (
                        <LabelValueListGroup direction="row" variant="flush">
                            <ListGroup.Item style={{ padding: '5px 0px', border: 0 }}>
                                <label>Overall</label>
                                <span>{team.recordData ? team.recordData.record : 'NA'}</span>
                            </ListGroup.Item>
                            <ListGroup.Item style={{ padding: '5px 0px', border: 0 }}>
                                <label>{team.teamId === game.awayTeam.teamId ? `Away: ` : `Home: `}</label>
                                <span>
                                    {team.teamId === game.awayTeam.teamId
                                        ? `${team.recordData ? team.recordData.awayRecord : 'NA'}`
                                        : `${team.recordData ? team.recordData.homeRecord : 'NA'}`}
                                </span>
                            </ListGroup.Item>
                        </LabelValueListGroup>
                    ) : (
                        <></>
                    )}
                </Card.Body>
                <Table
                    showHeader={false}
                    dataList={
                        !schedule || schedule.filter(game => game.points && game.opp_pts).length === 0
                            ? []
                            : schedule
                                  .filter(game => game.points && game.opp_pts)
                                  .slice(0)
                                  .reverse()
                                  .map((game, i) => {
                                      return {
                                          'schedule-game': `${moment(game.date).format('MM/DD/YY')}\n${game.location === 'away' ? '@' : 'vs.'} ${game.oppAbbr}`,
                                          'schedule-result': `${game.points}-${game.opp_pts} ${game.result}`,
                                      };
                                  })
                    }
                    autoHeight={true}
                    bordered
                    columns={[
                        {
                            header: 'Game',
                            dataKey: 'schedule-game',
                            flexGrow: 1,
                        },
                        {
                            header: 'Final',
                            dataKey: 'schedule-result',
                        },
                    ]}
                />
            </Card>
        );
    };

    const commonGames = team => {
        const schedule = team.teamId === game.awayTeam.teamId ? awayData.schedule : homeData.schedule;
        return (
            <Table
                showHeader={false}
                dataList={
                    !schedule || schedule.filter(game => game.points && game.opp_pts).length === 0
                        ? []
                        : schedule
                              .filter(game => game.points && game.opp_pts && commonOppIds.includes(game.oppId))
                              .sort((a, b) => (a.oppAbbr > b.oppAbbr ? 1 : -1))
                              .map((game, i) => {
                                  return {
                                      'schedule-game': `${moment(game.date).format('MM/DD/YY')}\n${game.location === 'away' ? '@' : 'vs.'} ${game.oppAbbr}`,
                                      'schedule-result': `${game.points}-${game.opp_pts} ${game.result}`,
                                  };
                              })
                }
                autoHeight={true}
                bordered
                columns={[
                    {
                        header: 'Game',
                        dataKey: 'schedule-game',
                        flexGrow: 1,
                    },
                    {
                        header: 'Final',
                        dataKey: 'schedule-result',
                    },
                ]}
            />
        );
    };

    const headToHead = team => {
        const schedule = team.teamId === game.awayTeam.teamId ? awayData.schedule : homeData.schedule;
        const otherTeamId = team.teamId === game.awayTeam.teamId ? game.homeTeam.teamId : game.awayTeam.teamId;
        return (
            <Table
                showHeader={false}
                dataList={
                    !schedule || schedule.filter(game => game.points && game.opp_pts).length === 0
                        ? []
                        : schedule
                              .filter(game => game.points && game.opp_pts && game.oppId === otherTeamId)
                              .map((game, i) => {
                                  return {
                                      'schedule-game': `${moment(game.date).format('MM/DD/YY')}\n${game.location === 'away' ? '@' : 'vs.'} ${game.oppAbbr}`,
                                      'schedule-result': `${game.points}-${game.opp_pts} ${game.result}`,
                                  };
                              })
                }
                autoHeight={true}
                bordered
                columns={[
                    {
                        header: 'Game',
                        dataKey: 'schedule-game',
                        flexGrow: 1,
                    },
                    {
                        header: 'Final',
                        dataKey: 'schedule-result',
                    },
                ]}
            />
        );
    };

    return (
        <InnerContent>
            <Container className="mt-2" fluid>
                <Row>
                    <Col>
                        <Nav variant="tabs" className="mb-2" activeKey={scheduleTab}>
                            <Nav.Item>
                                <Nav.Link onClick={e => setScheduleTab('Last 5 games')} eventKey="Last 5 games">
                                    Last 5 games
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={e => setScheduleTab('Head to Head')} eventKey="Head to Head">
                                    Head to Head
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={e => setScheduleTab('Common Games')} eventKey="Common Games">
                                    Common Games
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
                <TwoColumnView
                    columnOneJSX={
                        scheduleTab === 'Last 5 games'
                            ? renderLastFiveGames(game.awayTeam)
                            : scheduleTab === 'Head to Head'
                            ? headToHead(game.awayTeam)
                            : commonGames(game.awayTeam)
                    }
                    columnTwoJSX={
                        scheduleTab === 'Last 5 games'
                            ? renderLastFiveGames(game.homeTeam)
                            : scheduleTab === 'Head to Head'
                            ? headToHead(game.homeTeam)
                            : commonGames(game.homeTeam)
                    }
                />
            </Container>
        </InnerContent>
    );
};

export default Schedule;
